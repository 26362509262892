<template>
  <div class="row justify-content-center h-100">
    <!-- loader -->
    <div id="loader" style="display: none">
      <!--      <img src="assets/img/loading-icon.png" alt="icon" class="loading-icon">-->
    </div>
    <!-- * loader -->

    <!-- App Header -->
    <div class="appHeader no-border transparent position-absolute">
      <div class="left">
        <a href="#" class="headerButton goBack">
          <!--          <ion-icon name="chevron-back-outline"></ion-icon>-->
        </a>
      </div>
      <div class="pageTitle"></div>
      <div class="right">
      </div>
    </div>
    <!-- * App Header -->

    <!-- App Capsule -->
    <div id="appCapsule" class="col-sm-6 col-lg-4 col-xl-4">

      <div class="section mt-2 text-center">
        <img src="../assets/img/org-logo.png"/>
      </div>
      <div class="section mb-5 p-2">

        <form action="index.html">
          <div class="card">
            <div class="card-body pb-1">
              <div class="form-group basic">
                <div class="input-wrapper">
                  <label class="label" for="name">Name</label>
                  <input v-model="name" type="text" class="form-control" id="name" placeholder="Enter Your Name">
                  <i class="clear-input">
                    <!--                    <ion-icon name="close-circle"></ion-icon>-->
                  </i>
                  <div class="input-errors small text-danger justify-content-start d-flex" v-for="error of v$.name.$errors" :key="error.$uid">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>

              <div class="form-group basic">
                <div class="input-wrapper">
                  <label class="label" for="name">Gender</label>
                  <b-form-radio-group v-model="gender" class="form-check d-inline d-flex mt-1">
                    <b-form-radio value="Male" class="mr-2">Male</b-form-radio>
                    <b-form-radio value="Female">Female</b-form-radio>
                  </b-form-radio-group>
                  <i class="clear-input">
                    <!--                    <ion-icon name="close-circle"></ion-icon>-->
                  </i>
                  <div class="input-errors small text-danger justify-content-start d-flex" v-for="error of v$.gender.$errors" :key="error.$uid">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>

              <div class="form-group basic">
                <div class="input-wrapper">
                  <label class="label" for="fatherName">Name of Father</label>
                  <input v-model="fatherName" type="text" class="form-control" id="fatherName" placeholder="Enter Your Father Name">
                  <i class="clear-input">
                    <!--                    <ion-icon name="close-circle"></ion-icon>-->
                  </i>
                  <div class="input-errors small text-danger justify-content-start d-flex" v-for="error of v$.fatherName.$errors" :key="error.$uid">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>

              <div class="form-group basic">
                <div class="input-wrapper">
                  <label class="label" >Date Of Birth</label>
                  <b-datepicker v-model="dateOfBirth" :show-decade-nav="true" :min="dateOfBirthMaxAge" :max="dateOfBirthMinAge"></b-datepicker>
                  <i class="clear-input">
                    <!--                    <ion-icon name="close-circle"></ion-icon>-->
                  </i>
                  <div class="input-errors small text-danger justify-content-start d-flex" v-for="error of v$.dateOfBirth.$errors" :key="error.$uid">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>

              <div class="form-group basic">
                <div class="input-wrapper">
                  <label class="label" for="aadharNumber">Aadhar Number</label>
                  <input v-model="aadharNumber" type="text" class="form-control" id="aadharNumber" placeholder="Enter Your Aadhar Number">
                  <i class="clear-input">
                    <!--                    <ion-icon name="close-circle"></ion-icon>-->
                  </i>
                  <div class="input-errors small text-danger justify-content-start d-flex" v-for="error of v$.aadharNumber.$errors" :key="error.$uid">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>

              <div class="form-group basic">
                <div class="input-wrapper">
                  <label class="label" for="officialAddress">Official Address</label>
                  <input v-model="officialAddress" type="text" class="form-control" id="officialAddress" placeholder="Enter Your Official Address">
                  <i class="clear-input">
                    <!--                    <ion-icon name="close-circle"></ion-icon>-->
                  </i>
                  <div class="input-errors small text-danger justify-content-start d-flex" v-for="error of v$.officialAddress.$errors" :key="error.$uid">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>

              <div class="form-group basic">
                <div class="input-wrapper">
                  <label class="label" for="email">Email</label>
                  <input v-model="email" type="email" class="form-control" id="email" placeholder="Enter Your Email">
                  <i class="clear-input">
                    <!--                    <ion-icon name="close-circle"></ion-icon>-->
                  </i>
                  <div class="input-errors small text-danger justify-content-start d-flex" v-for="error of v$.email.$errors" :key="error.$uid">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>


              <div class="form-group basic">
                <div class="input-wrapper">
                  <label class="label" for="nominee">Nominee Name</label>
                  <input v-model="nominee" type="text" class="form-control" id="nominee" placeholder="Enter Nominee Name">
                  <i class="clear-input">
                    <!--                    <ion-icon name="close-circle"></ion-icon>-->
                  </i>
                  <div class="input-errors small text-danger justify-content-start d-flex" v-for="error of v$.nominee.$errors" :key="error.$uid">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>

              <div class="form-group basic">
                <div class="input-wrapper">
                  <label class="label" for="nomineeRelation">Nominee Relationship</label>
                  <input v-model="nomineeRelation" type="text" class="form-control" id="nomineeRelation" placeholder="Enter Nominee Relationship">
                  <i class="clear-input">
                    <!--                    <ion-icon name="close-circle"></ion-icon>-->
                  </i>
                  <div class="input-errors small text-danger justify-content-start d-flex" v-for="error of v$.nomineeRelation.$errors" :key="error.$uid">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>

              <div class="form-group basic">
                <div class="input-wrapper">
                  <label class="label" for="houseName">House Name / Number</label>
                  <input v-model="houseName" type="text" class="form-control" id="houseName" placeholder="Enter Your House Name or Number">
                  <i class="clear-input">
                    <!--                    <ion-icon name="close-circle"></ion-icon>-->
                  </i>
                  <div class="input-errors small text-danger justify-content-start d-flex" v-for="error of v$.houseName.$errors" :key="error.$uid">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>

              <div class="form-group basic">
                <div class="input-wrapper">
                  <label class="label" for="place">Place</label>
                  <input v-model="place" type="text" class="form-control" id="place" placeholder="Enter Your Place">
                  <i class="clear-input">
                    <!--                    <ion-icon name="close-circle"></ion-icon>-->
                  </i>
                  <div class="input-errors small text-danger justify-content-start d-flex" v-for="error of v$.place.$errors" :key="error.$uid">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>

              <div class="form-group basic">
                <div class="input-wrapper">
                  <label class="label" for="post">Post</label>
                  <input v-model="post" type="text" class="form-control" id="post" placeholder="Enter Your Post">
                  <i class="clear-input">
                    <!--                    <ion-icon name="close-circle"></ion-icon>-->
                  </i>
                  <div class="input-errors small text-danger justify-content-start d-flex" v-for="error of v$.post.$errors" :key="error.$uid">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>

              <div class="form-group basic">
                <div class="input-wrapper">
                  <label class="label" for="state">State</label>
                  <input v-model="state" type="text" class="form-control" id="state" placeholder="Enter Your State">
                  <i class="clear-input">
                    <!--                    <ion-icon name="close-circle"></ion-icon>-->
                  </i>
                  <div class="input-errors small text-danger justify-content-start d-flex" v-for="error of v$.state.$errors" :key="error.$uid">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>

              <div class="form-group basic">
                <div class="input-wrapper">
                  <label class="label" for="pincode">Pincode</label>
                  <input v-model="pincode" type="text" class="form-control" id="pincode" placeholder="Enter Your Place">
                  <i class="clear-input">
                    <!--                    <ion-icon name="close-circle"></ion-icon>-->
                  </i>
                  <div class="input-errors small text-danger justify-content-start d-flex" v-for="error of v$.pincode.$errors" :key="error.$uid">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>


              <div class="form-group basic">
                <div class="input-wrapper">
                  <label class="label" for="phone">Phone Number</label>
                  <input v-model="phone" type="phone" disabled class="form-control" id="phone" placeholder="Phone">
                  <i class="clear-input">
                    <!--                    <ion-icon name="close-circle"></ion-icon>-->
                  </i>
                  <div class="input-errors small text-danger justify-content-start d-flex" v-for="error of v$.phone.$errors" :key="error.$uid">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>

              <div class="transparent mb-2 mt-2">
                <button type="submit" class="btn btn-primary btn-block btn-lg" @click.prevent="registerMember"><b-spinner v-if="isWaitingResponse"></b-spinner><span v-else>Submit</span></button>
              </div>
            </div>
          </div>


        </form>
      </div>

    </div>
    <!-- * App Capsule -->
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, email, numeric } from '@vuelidate/validators'
export default {
  name: 'MembershipRegistration',
  data() {
    return {
      gender: '',
      fatherName: '',
      aadharNumber: '',
      officialAddress: '',
      nominee: '',
      nomineeRelation: '',
      houseName: '',
      place: '',
      post: '',
      state: '',
      pincode: '',
      name: '',
      email: '',
      dateOfBirth: '',
      phone: '',
      isWaitingResponse: false,
      dateOfBirthMaxAge: '1942-11-01', //this.$moment.subtract('80', 'years').format('YYYY-MM-DD'),
      dateOfBirthMinAge: this.$moment.format('YYYY-MM-DD')
    }
  },
  validations () {
    return {
      gender: {
        required
      },
      fatherName: {
        required,
      },
      aadharNumber: {
        required,
      },
      officialAddress: {
        required,
      },
      nominee: {
        required,
      },
      nomineeRelation: {
        required,
      },
      houseName: {
        required,
      },
      post: {
        required,
      },
      place: {
        required,
      },
      state: {
        required,
      },
      pincode: {
        required,
      },
      name: {
        required,
      },
      email: {
        required,
        email
      },
      dateOfBirth: {
        required
      },
      phone: {
        required,
        minLength: minLength(10),
        numeric
      }
    }
  },
  created() {
    this.phone = this.$route.query.phone?? ''
  },
  components: {
  },
  methods: {
    registerMember() {
      this.v$.$validate()
      if(this.v$.$error) return
      this.isWaitingResponse = true
      this.$axios.post('create-employee', {
        name: this.name,
        gender: this.gender,
        name_of_father: this.fatherName,
        aadhar_number: this.aadharNumber,
        official_address: this.officialAddress,
        nominee_name: this.nominee,
        nominee_relationship: this.nomineeRelation,
        house_name: this.houseName,
        place: this.place,
        post: this.post,
        state: this.state,
        pin_code: this.pincode,
        email: this.email,
        dob: this.dateOfBirth,
        phone_number: this.phone
      }).then(({data}) => {
        this.isWaitingResponse = false
        if(data.status === 1) window.location.href = data.data.enrollment_url
      }).catch(() => {
        this.isWaitingResponse = false
      })
    }
  },
  setup: () => {
    return { v$: useVuelidate() }
  }
}
</script>

<style scoped>
>>>.b-form-btn-label-control.form-control > .form-control {
  padding-top: 7px!important;
}
.mr-2 {
  margin-right: 20px;
}
>>>input[type="radio"] {
  margin-right: 5px !important;
}

</style>
